import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PedidoProveedor } from '../interfaces/pedidoProveedor.interface';

const base_url = `${ environment.base_url }/PedidosProveedor`;


@Injectable({
  providedIn: 'root'
})
export class PedidosProveedorService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  nuevoPedidoProveedor(data:PedidoProveedor){
    const url = `${ base_url }/postPedidoProveedor`;

    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarPedidoProveedor(data:PedidoProveedor){
    const url = `${ base_url }/putPedidoProveedor/${data.id!}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPedidoProveedorNumero(numero: number, ano:number, omitirCerrados: number = 0){
    const url = `${ base_url }/getPedidoProveedorNumero/${numero}/${ano}/${omitirCerrados}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getIdPedidoProveedorNumero(numero: number, ano:number, idProveedor: number){
    const url = `${ base_url }/getIdPedidoProveedorNumero/${numero}/${ano}/${idProveedor}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getPedidoProveedorId(idPedido: number, omitirCerrados: number = 0){
    const url = `${ base_url }/getPedidoProveedorId/${idPedido}/${omitirCerrados}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  borrarPedidoProveedor(idPedido: number){
    const url = `${ base_url }/borrarPedidoProveedor/${idPedido}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAllLote(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, codigoProveedor:string = '', desdeFecha: Date | string, hastaFecha: Date | string, desdeFechaEntrega: Date | string, hastaFechaEntrega: Date | string, orden:string = 'numeroPedido', desc:number = 0, omitirCerrados: number = 0, lote = ''){
    const url = `${ base_url }/getAllLote?page=${pagina}&rows=${rows}&denumc=${desdeNumero}&hanumc=${hastaNumero
                  }&codProc=${codigoProveedor}&defeec=${desdeFechaEntrega}&hafeec=${hastaFechaEntrega
                  }&defecc=${desdeFecha}&hafecc=${hastaFecha}&orden=${orden}&desc=${desc}&cerr=${omitirCerrados}&lote=${lote}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getAll(pagina:number =1, rows:number = 15, desdeNumero:number = 0, hastaNumero: number = 9999999999, codigoProveedor:string = '', desdeFecha: Date | string, hastaFecha: Date | string, desdeFechaEntrega: Date | string, hastaFechaEntrega: Date | string, orden:string = 'numeroPedido', desc:number = 0, omitirCerrados: number = 0){
    const url = `${ base_url }/getAll?page=${pagina}&rows=${rows}&denum=${desdeNumero}&hanum=${hastaNumero
                  }&codPro=${codigoProveedor}&defee=${desdeFechaEntrega}&hafee=${hastaFechaEntrega
                  }&defec=${desdeFecha}&hafec=${hastaFecha}&orden=${orden}&desc=${desc}&cerr=${omitirCerrados}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getLineasPedidosIds(data:Number[]){
    const url = `${ base_url }/getLineasPedidosIds`;

    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busqueda(valor:string, pagina:number, rows:number){
    const url = `${ base_url }/getBusquedaPedido/${valor}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  busquedaGlobal(valor:string, pagina:number, rows:number, desdeFecha: string, hastaFecha: string){
    const url = `${ base_url }/getBusquedaGlobalPedido/${valor}/${desdeFecha}/${hastaFecha}?page=${pagina}&rows=${rows}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }



}
