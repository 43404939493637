<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />
<app-spinner *ngIf="spinner"></app-spinner>
<br />
<br />
<br />
<br />

<div class="container mt-5">
   <div class="centrado">
      <form class="bordeContenedor anchoFormulario fondoFormulario" autocomplete="off" *ngIf="!activarLogout"
         [formGroup]="formulario" (ngSubmit)="guardar()">
         <!-- <div> -->
         <div class="form-group row">
            <!-- <div class="col-lg-4 col-md-5"></div> -->
            <div class="col-lg-2 col-md-1"></div>
            <!-- <label class="col-lg-1 col-md-2 col-form-label">Usuario</label> -->
            <label class="col-lg-2 col-md-4 col-form-label">Usuario</label>
            <!-- <div class="col-lg-2 col-md-4"> -->
            <div class="col-lg-5 col-md-6">
               <input mTab class="form-control" id="usuario" type="text" placeholder="" formControlName="usuario" />
               <small *ngIf="campoNoValido('usuario')" class="form-text text-danger">Debe contener un mínimo de 4
                  caracteres</small>
            </div>
         </div>

         <div class="form-group row mt-3">
            <!-- <div class="col-lg-4 col-md-5"></div> -->
            <div class="col-lg-2 col-md-1"></div>
            <!-- <label class="col-lg-1 col-md-2 col-form-label">Password</label> -->
            <label class="col-lg-2 col-md-4 col-form-label">Password</label>
            <!-- <div class="col-lg-2 col-md-4"> -->
            <div class="col-lg-5 col-md-6">
               <input mTab class="form-control" type="password" placeholder="" formControlName="password" />
               <!-- <input class="form-control" type="text" placeholder="Password" formControlName="password"> -->
               <small *ngIf="campoNoValido('password')" class="form-text text-danger">Debe contener un mínimo de 4
                  caracteres</small>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-4 col-md-5"></div>

            <!-- <div class="col-lg-3 col-md-6 mt-3"> -->
            <div class="col-lg-5 col-md-6 mt-3">
               <button type="submit" class="btn btn-primary btn-block text-center anchoCompleto">
                  {{ textoBoton }}
               </button>
            </div>
         </div>

         <!-- </div> -->
      </form>
   </div>

   <div *ngIf="activarLogout">
      <br />
      <br />
      <br />
      <div class="desdeAbajo">
         <img src="assets/logoGrande.jpg" class="imagen centrado" />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <!-- <div class="container desdeDerecha"> -->
      <!-- <div class="row"> -->
      <!-- <h2 class="centrado aazul desdeDerecha" >Bienvenido {{nombreUsuarioActivo}}</h2> -->
      <h2 class="aazul desdeDerecha bienvenido">
         Saludos {{ nombreUsuarioActivo }}
      </h2>

      <!-- </div> -->
      <!-- </div> -->
      <br />
      <br />
      <br />
      <br />
      <button (click)="logout()" class="btn btn-primary text-center boton desdeIzquierda">
         {{ textoBoton }}
      </button>
      <br />
      <br />
      <br />
         <!-- ----------------------------------------------------------------------- -->
         <!--                         FORMULARIO CERTIFICADO                          -->
         <!-- ----------------------------------------------------------------------- -->
      <div style="animation: fadein 8s">
         <form class="fondoFormulario" autocomplete="off" *ngIf="pedirCertificado"
            [formGroup]="formularioC" (ngSubmit)="guardarCertificado()">
            <div class="rojo row">
               <div class="col-lg-1 text-end">
                   <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                   <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
               </svg>
               </div>
               <div class="col mt-3">
                  ATENCIÓN: Sin la contraseña del certificado no será posible facturar.
               </div>
           </div>
           
           
            <div class="form-group row">
               <div class="col-2"></div>
               <label class="col-2 col-form-label text-end">Password del Certificado</label>
               <div class="col-2">
                  <input mTab class="form-control" type="password" placeholder="" formControlName="passwordC" />
                  <small *ngIf="campoNoValido('password')" class="form-text text-danger">Debe contener un mínimo de 4
                     caracteres</small>
               </div>
               <div class="col-2">
                  <button type="submit" class="btn btn-info btn-block text-center anchoCompleto">
                     Validar
                  </button>
               </div>
            </div>
            
         </form>

         <div *ngIf="mostrarBorrarCertificado">
            <button (click)="borrarCertificado()" class="btn btn-danger text-center">
                Borrar Clave Certificado 
            </button>
         </div>
      </div>
      <div *ngIf="mostrarFrase">
         <style>
            @keyframes fadein {
               from {
                  opacity: 0;
               }

               to {
                  opacity: 1;
               }
            }
         </style>
         <!-- <div  style="animation: fadein 12s; animation-delay: 2s;">  -->
         <div style="animation: fadein 18s">
            <br />

            <blockquote class="text-center">
               <p class="fuenteMedia text-center">
                  <em> {{ fraseDelDia.frase }}</em>
               </p>
               <footer>
                  <cite>{{ fraseDelDia.autor }}</cite>
               </footer>
            </blockquote>
         </div>
      </div>

      <!-- <div class="container desdeIzquierda"> -->
      <!-- <div class="row centrado borde "> -->
      <!-- <div class="col-lg-2"></div> -->
      <!-- <div class="col-lg-3 mt-3 "> -->
      <!-- <button (click)="logout()"  class="btn btn-primary btn-block text-center anchoCompleto">{{textoBoton}}</button> -->
      <!-- </div> -->

      <!-- </div> -->
      <!-- </div> -->
   </div>
   <div class="versiones fuenteReducida">
      <div>Versión BD: {{ versionBD | number : "1.3-3" }}</div>
      <div>Versión: {{ versionFr | number : "1.3-3" }}</div>
   </div>
</div>

<!-- <app-modal titulo={{tituloForm}} texto={{textoModal}}></app-modal> -->
<app-mensaje [mensaje]=mensaje (botonesClick)="controlBotones($event)"></app-mensaje>
