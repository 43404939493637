<div class="mensaje d-print-none traerAlFrente">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </symbol>
</svg>


    <div *ngIf="mensaje.warning && hayTiempo" class="alert alert-warning alert-dismissible fade-in-image d-flex mt-5" role="alert">
        <div>
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg> {{mensaje.textoWarning}}
            <!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
            <button type="button" class="btn-close" aria-label="Close" (click)="mensaje.warning = false; mensaje.persistente = false;"></button>
        </div>
        <div *ngIf="mensaje.botones" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3" data-bs-dismiss="alert">Aceptar</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3" data-bs-dismiss="alert">Cancelar</button>
            </div>
        </div>
    </div>

    <div *ngIf="mensaje.alert && hayTiempo" class="alert alert-primary alert-dismissible fade-in-image d-flex mt-5" role="alert">
        <div>
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg> {{mensaje.textoAlert}}
            <button type="button" class="btn-close" (click)="mensaje.alert = false; mensaje.persistente = false;" aria-label="Close"></button>
        </div>
        <div *ngIf="mensaje.botones" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3">Aceptar</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3">Cancelar</button>
            </div>
        </div>
    </div>

    <div *ngIf="mensaje.success && hayTiempo" class="alert alert-success alert-dismissible fade-in-image d-flex mt-5" role="alert">
        <div>
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg> {{mensaje.textoSuccess}}
            <button type="button" class="btn-close" (click)="mensaje.success = false; mensaje.persistente = false;" aria-label="Close"></button>
        </div>
        <div *ngIf="mensaje.botones" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3" data-bs-dismiss="alert">Aceptar</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3" data-bs-dismiss="alert">Cancelar</button>
            </div>
        </div>
    </div>

    <div *ngIf="mensaje.refresh && hayTiempo" class="alert alert-success alert-dismissible fade-in-image d-flex mt-5" role="alert">
        <div>
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg> Validación correcta. Pulse F5 o Refresque la página
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
            </svg> para continuar.
            <button type="button" class="btn-close" (click)="mensaje.refresh = false; mensaje.persistente = false;" aria-label="Close"></button>
        </div>
        <div *ngIf="mensaje.botones" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3" data-bs-dismiss="alert">Aceptar</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3" data-bs-dismiss="alert">Cancelar</button>
            </div>
        </div>
    </div>

    <div *ngIf="mensaje.danger && hayTiempo" class="alert alert-danger alert-dismissible fade-in-image d-flex mt-5" role="alert">
        <div>
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg> {{mensaje.textoDanger}}
            <button type="button" class="btn-close" (click)="mensaje.danger = false; mensaje.persistente = false;" aria-label="Close"></button>
        </div>
        <div *ngIf="mensaje.botones" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3" (click)="aceptar();">Aceptar</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3" (click)="cancelar();">Cancelar</button>
            </div>
        </div>
        <div *ngIf="mensaje.siNo" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3" (click)="aceptar();">Sí</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3" (click)="cancelar();">No</button>
            </div>
        </div>
        <div *ngIf="mensaje.devolver" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3" (click)="aceptar();">Devolver Mercancía al Almacén</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3" (click)="noDevolver();">No Devolver Mercancía al Almacén</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
               <button type="button" class="btn btn-outline-secondary ml-3" (click)="cancelar();">Cancelar</button>
           </div>
        </div>
    </div>

    <div *ngIf="mensaje.info && hayTiempo" class="alert alert-info alert-dismissible fade-in-image d-flex mt-5" role="alert">
        <div>
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#exclamation-triangle-fill"/></svg> {{mensaje.textoInfo}}
            <button type="button" class="btn-close" (click)="mensaje.info = false; mensaje.persistente = false;" aria-label="Close"></button>
        </div>
        <div *ngIf="mensaje.botones" class="btn-toolbar mt-5 ml-auto" role="toolbar">
            <div class="btn-group me-2" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-secondary mr-3" data-bs-dismiss="alert">Aceptar</button>
            </div>
            <div class="btn-group me-2" role="group" aria-label="Second group">
                <button type="button" class="btn btn-outline-secondary ml-3" data-bs-dismiss="alert">Cancelar</button>
            </div>
        </div>
    </div>
</div>