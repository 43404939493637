import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mensaje',
  templateUrl: './mensaje.component.html',
  styleUrls: ['./mensaje.component.css']
})
export class MensajeComponent implements OnInit {

  @Input() mensaje: Mensaje = {
            
            botones: false,
            danger:false,
            success:false,
            warning:false,
            alert:false,
            info: false,
            refresh: false,
            persistente:false,
            comandoAceptar: '',
            comandoCancelar:'',
            comandoNoDevolver: '',
            siNo: false,
            devolver: false,
          };

  @Output() botonesClick:EventEmitter<number> = new EventEmitter();
  public leerMensaje: boolean = environment.activarVoz;
  constructor(
             private misFuncionesService: MisFuncionesService,
  ) { }

  

  ngOnInit(): void {
   
  }

  // El mensaje se borrará a los 5 segundos salvo que venga como persistente.
  
  get hayTiempo(){
    if (this.leerMensaje){this.leeMensaje();}

    if (!this.mensaje.persistente){
      setTimeout(() => {
        this.mensaje.danger =false;
        this.mensaje.success =false;
        this.mensaje.warning =false;
        this.mensaje.alert =false;
        this.mensaje.info = false;
        this.mensaje.refresh = false;
      }, 3000);
    }
    return true;
  }

  cancelar(){
    this.botonesClick.emit(0);
  }
  aceptar(){
    this.botonesClick.emit(1);
  }
  noDevolver(){
   this.botonesClick.emit(2);
  }

  leeMensaje(){
   
    if(this.mensaje.alert){
      this.misFuncionesService.leer(this.mensaje.textoAlert!);
      this.leerMensaje = false;
    }

    if(this.mensaje.danger){
      this.misFuncionesService.leer(this.mensaje.textoDanger!);
      this.leerMensaje = false;
    }

    if(this.mensaje.info){
      this.misFuncionesService.leer(this.mensaje.textoInfo!);
      this.leerMensaje = false;
    }

    if(this.mensaje.success){
      this.misFuncionesService.leer(this.mensaje.textoSuccess!);
      this.leerMensaje = false;
    }

    if(this.mensaje.warning){
      this.misFuncionesService.leer(this.mensaje.textoWarning!);
      this.leerMensaje = false;
    }

    if(this.mensaje.refresh){
      this.misFuncionesService.leer(this.mensaje.textoSuccess!);
      this.leerMensaje = false;
    }

    
  }

  

}
